<template>
  <div style="padding-right: 15px">
    <Breadcrumbs
      page_title="library"
      :items="breadcrumbs"
      :item_no="item_no ? item_no : 0"
      :item_icon="true"
      :item_btn_add="true"
      :btn_text="'AddCategory'"
      @ShowAction="ShowAction"
      @sort_categories="sort_categories"
    />
    <v-container fluid>
      <v-row v-if="loading" class="mt-5">
        <v-col cols="2" v-for="n in 12" :key="n">
          <v-skeleton-loader
            class="mx-auto"
            max-width="150"
            max-height="150"
            type="image"
          ></v-skeleton-loader>
        </v-col>
      </v-row>

      <v-row class="mr-1" v-else>
        <v-card
          class="mx-3 my-5"
          style="border-radius: 16px"
          v-show="bullet == 'bullet'"
        >
          <v-list>
            <v-list-item-group
              v-for="item in items"
              :key="item.id"
              class="list-style"
            >
              <v-list-item
                @click="goLibraryWithName(item, (showEditDialog = false))"
              >
                <v-col cols="12" md="2" lg="1" v-show="item.status == true">
                  <v-img :src="item.orgImage" class="list_img"></v-img>
                </v-col>

                <v-col cols="12" md="2" lg="1" v-show="item.status == false">
                  <v-img :src="item.orgImage" class="inactivelist_img"></v-img>
                </v-col>

                <v-col cols="12" md="8" lg="9">
                  <v-list-item-content style="color: #424242">
                    <v-list-item-title style="font-size: 18px">{{
                      item.topicName
                    }}</v-list-item-title>

                    <v-list-item-subtitle>{{
                      item.description
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-col>

                <v-col cols="12" md="2" lg="2" class="d-flex justify-end">
                  <v-sheet
                    height="20px"
                    width="65px"
                    line-height="30px"
                    v-show="item.status == true"
                    class="status_list"
                  >
                    <v-icon class="circle">mdi-circle</v-icon>
                    {{ $t("active") }}
                  </v-sheet>
                  <v-sheet
                    height="20px"
                    width="65px"
                    line-height="30px"
                    v-show="item.status == false"
                    class="inactivestatus_list"
                  >
                    <v-icon class="circle">mdi-circle</v-icon>
                    {{ $t("inactive") }}
                  </v-sheet>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        text
                        v-bind="attrs"
                        v-on="on"
                        class="editbtn"
                        style="margin-bottom: 5px"
                      >
                        <v-icon style="color: #424242"
                          >mdi-dots-vertical</v-icon
                        >
                      </v-btn>
                    </template>
                    <v-list
                      class="d-flex flex-column align-start"
                      style="padding: 0; border-radius: 8px"
                    >
                      <v-list-item class="list-item-padding preview">
                        <v-btn
                          color="#424242"
                          style="font-size: 11px; width: 100px"
                          text
                          class="text-capitalize"
                          @click="
                            goLibraryWithName(item, (showEditDialog = true))
                          "
                        >
                          <v-icon left>mdi-file-document-edit-outline</v-icon>
                          {{ $t("edit") }}
                        </v-btn>
                      </v-list-item>
                      <v-list-item class="list-item-padding preview">
                        <v-btn
                          color="error"
                          style="font-size: 11px; width: 100px"
                          text
                          class="text-capitalize"
                          @click="checkTopicDelete(item)"
                        >
                          <v-icon left>mdi-trash-can-outline</v-icon>
                          {{ $t("delete") }}
                        </v-btn>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>

        <template v-if="grid == 'grid'">
          <v-col
            cols="12"
            sm="6"
            md="3"
            lg="2"
            v-for="item in items"
            :key="item.id"
            class="library lg5-custom"
          >
            <v-card
              class="mx-auto library_card"
              style="border-radius: 16px; padding-top: 12px"
              elevation="3"
              height="250"
              @click="goLibraryWithName(item, (showEditDialog = false))"
            >
              <v-img
                :src="item.orgImage"
                height="160px"
                class="library_img"
                v-show="item.status == true"
              >
                <v-sheet
                  height="20px"
                  width="60px"
                  line-height="30px"
                  class="status"
                >
                  <v-icon class="circle">mdi-circle</v-icon>
                  {{ $t("active") }}
                </v-sheet>

                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      text
                      v-bind="attrs"
                      v-on="on"
                      class="editbtn"
                      style="left: 83%; top: 4%"
                    >
                      <v-icon style="color: #ffffff">mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list
                    class="d-flex flex-column align-start"
                    style="padding: 0; border-radius: 8px"
                  >
                    <v-list-item class="list-item-padding preview">
                      <v-btn
                        color="#424242"
                        style="font-size: 11px; width: 100px"
                        text
                        class="text-capitalize"
                        @click="
                          goLibraryWithName(item, (showEditDialog = true))
                        "
                      >
                        <v-icon left>mdi-file-document-edit-outline</v-icon>
                        {{ $t("edit") }}
                      </v-btn>
                    </v-list-item>
                    <v-list-item class="list-item-padding preview">
                      <v-btn
                        color="error"
                        style="font-size: 11px; width: 100px"
                        text
                        class="text-capitalize"
                        @click="checkTopicDelete(item)"
                      >
                        <v-icon left>mdi-trash-can-outline</v-icon>
                        {{ $t("delete") }}
                      </v-btn>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-img>

              <v-img
                :src="item.orgImage"
                height="160px"
                class="library_img_inactive"
                v-show="item.status == false"
              >
                <v-sheet
                  v-show="item.status == false"
                  height="20px"
                  width="60px"
                  line-height="30px"
                  class="inactive_status"
                >
                  <v-icon class="circle">mdi-circle</v-icon>
                  {{ $t("inactive") }}
                </v-sheet>

                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      text
                      v-bind="attrs"
                      v-on="on"
                      class="editbtn"
                      style="left: 83%; top: 4%"
                    >
                      <v-icon style="color: #ffffff">mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list
                    class="d-flex flex-column align-start"
                    style="padding: 0; border-radius: 8px"
                  >
                    <v-list-item class="list-item-padding preview">
                      <v-btn
                        color="#424242"
                        style="font-size: 11px; width: 100px"
                        text
                        class="text-capitalize"
                        @click="
                          goLibraryWithName(item, (showEditDialog = true))
                        "
                      >
                        <v-icon left>mdi-file-document-edit-outline</v-icon>
                        {{ $t("edit") }}
                      </v-btn>
                    </v-list-item>
                    <v-list-item class="list-item-padding preview">
                      <v-btn
                        color="error"
                        style="font-size: 11px; width: 100px"
                        text
                        class="text-capitalize"
                        @click="checkTopicDelete(item)"
                      >
                        <v-icon left>mdi-trash-can-outline</v-icon>
                        {{ $t("delete") }}
                      </v-btn>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-img>

              <v-card-title>
                <v-row align="center">
                  <p
                    class="text_block"
                    style="
                      font-size: 16px;
                      color: #000000;
                      opacity: 1;
                      text-align: start;
                      padding-left: 12px;
                      width: 190px;
                      display: inline-block;
                      text-overflow: ellipsis;
                      overflow: hidden;
                      white-space: nowrap;
                    "
                  >
                    {{ item.topicName }}
                  </p>
                </v-row>
              </v-card-title>

              <v-card-subtitle
                style="
                  width: 190px;
                  display: inline-block;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
                "
                >{{ item.description }}</v-card-subtitle
              >
            </v-card>
          </v-col>
        </template>
      </v-row>
      <cancel-save v-if="$store.getters.showLibraryAction" />
    </v-container>
    <Add
      :show="addDialog"
      @close="addDialog = false"
      :sortNumberItem="sortNumberItems"
      :fullName="fullName_"
      @confirm="
        (addDialog = false),
          getTopic(),
          (successDialog1 = true),
          (message = $t('addtopicsuccessful'))
      "
    />
    <Edit
      :show="editDialog"
      :editData="editData"
      :sortNumberItem="sortNumberItems"
      @close="editDialog = false"
      @confirm="
        (editDialog = false),
          getTopic(),
          (successDialog1 = true),
          (message = $t('updatetopicsuccessful'))
      "
    />
    <!-- delete -->
    <DeleteConfirmDialog
      :text="deleteConfirmText"
      :show="deleteDialog"
      @close="deleteDialog = false"
      @confirm="deleteConfirm"
    />
    <SuccessDialog
      :show="successDialog1"
      :title="$t('success')"
      :text="message"
      @close="(successDialog1 = false), getTopic()"
    />
    <PermissionDialog
      :show="permissionDialog"
      title="Error"
      :text="permissionMessage"
      @close="permissionDialog = false"
    />
    <cookiesPrivacy
      :show="Dialog"
      @accept="(Dialog = false), SetAnnouncement()"
      @later="Dialog = false"
    />

    <Announcement
      v-if="$store.getters.isLoggedIn"
      :AnnouncementItems="announcements"
      :show="announcementDialog"
      @close="announcementDialog = false"
    />
  </div>
</template>

<script>
import CancelSave from "../../../components/cancelSave.vue";
import Add from "./add.vue";
import cookiesPrivacy from "../../Auth/CookiesAndPrivacy.vue";
import Edit from "./edit.vue";
import Announcement from "../../../components/Announcement.vue";

export default {
  components: {
    Add,
    Edit,
    CancelSave,
    cookiesPrivacy,
    Announcement,
  },
  data: () => ({
    announcements: [],
    announcementDialog: false,
    editData: {},
    editDialog: false,
    deleteTopicName: null,
    deleteTopic_id: 0,
    item_no: 0,
    fullName_: "",
    sortNumberItems: [],
    permissionDialog: false,
    message: "",
    permissionMessage: "",
    breadcrumbs: [
      {
        sidebar_tilte: "home",
      },
      {
        text: "library",
      },
    ],
    deleteDialog: false,
    addDialog: false,
    successDialog1: false,
    items: [],
    loading: true,
    Dialog: false,
    loginImg: require("@/assets/f16c977981a56c6e91ef156ce3819ec1.png"),
    grid: "grid",
    bullet: "",
    overlay: false,
  }),
  computed: {
    deleteConfirmText() {
      return this.$t("suretodeletetopictitle") + ` #${this.deleteTopicName} ?`;
    },
  },
  mounted() {
    const auth = JSON.parse(
      localStorage.getItem("vivek_authenticated_user_data")
    );
    if (auth) {
      this.fullName_ = auth.fullName;
    }

    this.getTopic();
  },
  methods: {
    SetAnnouncement() {
      this.announcementDialog = this.announcements?.length != 0;    
    },
    async getAnnouncement() {
      let self = this;      
      await self.$axios
        .get(
          `${self.web_url}Announcement/GetAnnouncementForCustomerWeb?comId=` +
            localStorage.getItem("companyID") +
            "&userId=" +
            parseInt(localStorage.getItem("UserID"))
        )
        .then(function (res) {          
          if (res.data.status == 0) {
            self.announcements = res.data.data?.map((x) => ({
              ...x,
              showImage: true,
              doNotRepeat: false,
            }));          
            if (self.announcements?.length == 0) {
              self.announcementDialog = false;
              self.$store.commit("setLoggedIn", false);
              self.loading = false;
            } else {
              self.announcementDialog = !self.Dialog; 
              self.loading = false;
            }
          }
        })
        .catch(function (err) {
          throw err;
        });
    },
    async deleteConfirm() {
      const res = await this.$axios.post(`${this.web_url}Topic/RemoveTopic`, {
        id: this.deleteTopic_id,
      });
      if (res.data.status == 0) {
        this.getTopic();
        this.deleteDialog = false;
        this.message = this.$t("deletetopicsuccessful");
        this.successDialog1 = true;
      }
    },
    async checkTopicDelete(item) {
      this.deleteTopicName = item.topicName;
      this.deleteTopic_id = item.id;
      let self = this;
      let checkPermission = self.check_Permissions("library");
      if (checkPermission) {
        self.deleteDialog = true;
      } else {
        self.permissionDialog = true;
        self.permissionMessage = "You don't have permission to delete Topic.";
      }
    },
    async checkTopicAdd() {
      let self = this;
      let checkPermission = self.check_Permissions("library");
      if (checkPermission) {
        self.addDialog = true;
      } else {
        self.permissionDialog = true;
        self.permissionMessage = "You don't have permission to add Topic.";
      }
    },
    async getTopic() {
      let self = this;
      let policy = localStorage.getItem("policyType");
      if (policy == "false") {
        self.Dialog = true;
      }

      self.loading = true;
      await self.$axios
        .get(
          `${self.web_url}Topic/GetTopics?id=` +
            localStorage.getItem("companyID")
        )
        .then(function (res) {
          self.items = res.data.data.map((v, i) => ({
            ...v,
            no: i + 1,
          }));
          self.item_no = self.items ? self.items.length : 0;
          let temp_null = [{ value: "" }];
          let temp_num = self.items.map((v, i) => {
            return { value: i + 1 };
          });
          self.sortNumberItems = temp_null.concat(temp_num);
          //GETTING ANNOUNCEMENT...
          if (self.$store.getters.isLoggedIn) {
            self.getAnnouncement();
          }
        })
        .catch(function (err) {
          alert(err);
        });
      self.loading = false;
    },

    goLibraryWithName(item, show) {
      let checkPermission = this.check_Permissions("library");
      if (show) {
        if (checkPermission) {
          this.editDialog = true;
          this.editData = item;
        } else {
          this.permissionDialog = true;
          this.permissionMessage = "You don't have permission to edit Topic.";
        }
      } else {
        this.$router.push("/home/library/" + item.topicName);
        this.$store.commit("saveEditLibrary", item);
        localStorage.setItem("libraryId", item.id);
      }
    },

    ShowAction(v) {
      if (v == "Add") {
        this.checkTopicAdd();
      }
    },
    sort_categories(x) {
      if (x == "grid") {
        this.grid = null;
        this.bullet = null;
        this.grid = x;
      } else {
        this.grid = null;
        this.bullet = null;
        this.bullet = x;
      }
    },
  },
};
</script>

<style scoped>
.text_block {
  width: 100%;
  word-break: normal;
  overflow-wrap: break-word;
}
.library {
  padding-right: 0px !important;
}
.library_img {
  margin: 0px 12px;
  border-radius: 16px;
}
.library_card:hover .editbtn {
  visibility: visible;
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
.editbtn:focus {
  background: #ffffff;
  border: 1px solid #ffffff;
}
.library_card .editbtn {
  visibility: hidden;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}
.library_img_inactive {
  margin: 0px 12px;
  border-radius: 16px;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}
.status {
  border-radius: 6px;
  font-size: 11px;
  text-align: center;
  padding: 2px;
  margin: 12px;
  background: #6fdc42;
  color: #ffffff;
  display: inline-table;
}
.inactive_status {
  border-radius: 6px;
  font-size: 11px;
  text-align: center;
  padding: 2px;
  margin: 12px;
  background: #c4c4c4;
  color: #ffffff;
  display: inline-table;
}
.status_list {
  border-radius: 6px;
  font-size: 13px;
  text-align: center;
  padding: 2px;
  margin: 5px 40px 0px 0px;
  background: #6fdc42;
  color: #ffffff;
  display: inline-table;
}
.inactivestatus_list {
  border-radius: 6px;
  font-size: 13px;
  text-align: center;
  padding: 2px;
  margin: 5px 40px 0px 0px;
  background: #c4c4c4;
  color: #ffffff;
  display: inline-table;
}
.circle {
  margin-bottom: 2px;
  color: white !important;
  font-size: 8px !important;
}
.editbtn {
  height: 30px !important;
  width: 20px !important;
  min-width: 20px !important;
  position: absolute;
  background: rgba(255, 255, 255, 0.5);
  transition: 0.3s;
  border: 1px solid #ffffff;
}
.list_img {
  border-radius: 12px;
}
.inactivelist_img {
  border-radius: 12px;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}
.list-style {
  border-bottom: 1px solid rgba(66, 66, 66, 0.2);
}
</style>

<style>
@media (min-width: 1264px) and (max-width: 1903px) {
  .lg5-custom {
    width: 20%;
    max-width: 20%;
    flex-basis: 20%;
  }
}
</style>

<style scoped>
.list-item-padding.v-sheet.v-list {
  border-radius: 8px !important;
}
.list-item-padding.theme--light.v-list-item:not(.v-list-item--active):not(
    .v-list-item--disabled
  ):hover {
  border-radius: 8px !important;
}
.list-item-padding.list-item-padding.theme--light {
  padding: 0 5px !important;
  min-height: 42px !important;
}
::v-deep .list-item-padding.v-list-item:hover {
  background-color: unset !important;
}
</style>
