<template>
  <div>
    <v-dialog
      v-model="showAnnouncement"
      persistent
      :overlay-opacity="0.4"
      content-class="elevation-0 preview_class"
    >
      <v-card
        v-for="(item, index) in AnnouncementItems"
        :key="index"
        overflow="hidden"
        color="transparent"
        style="box-shadow: unset !important;margin-bottom: 2.2rem;"
      >
        <v-card-title style="padding-right: 0; padding-bottom: 5px">
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="closeAnnouncement(item, index)"
            color="#FFFFFF"
            class="custom_close_btn"
            style="border-radius: 50px !important; border: 1px solid #ffffff"
          >
            <v-icon class="custom_close_btn">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <div class="image-container">
          <v-img
            style="border-radius: 8px; margin: auto"
            :src="item.orgImage"
            @click="openLink(item.linkURL)"
            class="announcement_imgClass"
          ></v-img>
        </div>
        <v-card-actions class="d-flex justify-end" style="padding: 0">
          <v-checkbox
            @click="doNotRepeatSave(item,true)"
            class="mt-0 custom_chk mr-6"
            v-model="item.doNotRepeat"
            :label="$t('donotrepeat')"
            color="#ffffff"
            hide-details
          ></v-checkbox>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    AnnouncementItems: {
      type: Array,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    announcements: [],
    announcement: null,
  }),
  computed: {
    showAnnouncement: {
      get() {
        return this.show;
      },
      set() {
        this.$emit("close");
      },
    },
  },
  mounted() {},
  methods: {
    openLink(link) {
      if (link) {
        if (!link.startsWith("https://")) {
          link = "https://" + link;
        }
        window.open(link, "_blank");
      }
    },
    closeAnnouncement(item) {
      this.doNotRepeatSave(item,false);
    },
    commonMethods(item){
      const index = this.AnnouncementItems.findIndex((a) => a.id === item.id);
      if (index !== -1) {
        this.AnnouncementItems.splice(index, 1);
      }
      if (this.AnnouncementItems.length == 0) {
        this.showAnnouncement = false;
        this.$store.commit("setLoggedIn", false);
      }
    },
    async doNotRepeatSave(item,flag) {
      const userdata = JSON.parse(
        localStorage.getItem("vivek_authenticated_user_data")
      );
      const memberid = userdata.memberId;

      let self = this;
      let request = {
        announcementID: item.id,
        companyID: localStorage.getItem("companyID"),
        userID: parseInt(localStorage.getItem("UserID")),
        doNotRepeatFlag: flag,
        createBy: memberid,
      };

      await self.$axios
        .post(`${self.web_url}Announcement/RecordUserNoRepeatChoice`, request)
        .then(function (res) {
          if (res.data.status == 0) {
            self.commonMethods(item);
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
::v-deep .custom_chk .theme--light.v-label {
  color: #ffffff;
  font-size: 16px;
}
::v-deep .custom_chk .v-input--selection-controls__input .v-icon {
  color: #ffffff;
}
/* Announcement scroll */
::v-deep .preview_class::-webkit-scrollbar {
  width: 1px;
  height: 1ch;
}
::v-deep .preview_class::-webkit-scrollbar-track {
  background: transparent;
}
::v-deep .preview_class::-webkit-scrollbar-thumb {
  background: transparent;
}
::v-deep .preview_class::-webkit-scrollbar-thumb:hover {
  background: transparent;
}
</style>

<style scoped>
.custom_close_btn {
  height: 35px;
  width: 35px;
  font-size: 24px;
}

::v-deep .preview_class.v-dialog {
  overflow: hidden !important;
  max-width: 80%;
  max-height: 85%;
  width: 400px;
  height: 580px;
  margin-bottom: 0;
  margin-top: 0;
}
::v-deep .announcement_imgClass {
  width: 350px;
  height: 450px;
}
@media screen and (max-width: 400px) {
  ::v-deep .preview_class.v-dialog {
    max-width: 70%;
    max-height: 100%;
    width: 125px;
    height: 180px;
  }
  ::v-deep .custom_chk .v-input--selection-controls__input .v-icon {
    color: #ffffff;
    font-size: 12px !important;
  }
  .announcement_imgClass {
    width: 100px;
    height: 95px;
  }
  ::v-deep .custom_chk .theme--light.v-label {
    color: #ffffff;
    font-size: 8px;
  }
  .custom_close_btn {
    height: 14px;
    width: 14px;
    font-size: 10px;
  }
  .v-btn--icon.v-size--default .v-icon,
  .v-btn--fab.v-size--default .v-icon {
    height: 14px;
    font-size: 14px;
    width: 14px;
  }
}
@media screen and (min-width: 401px) and (max-width: 599px) {
  ::v-deep .preview_class.v-dialog {
    max-width: 70%;
    max-height: 100%;
    width: 200px;
    height: 280px;
  }
  ::v-deep .custom_chk .v-input--selection-controls__input .v-icon {
    color: #ffffff;
    font-size: 18px !important;
  }
  .announcement_imgClass {
    width: 170px;
    height: 180px;
  }
  ::v-deep .custom_chk .theme--light.v-label {
    color: #ffffff;
    font-size: 10px;
  }
  .custom_close_btn {
    height: 20px;
    width: 20px;
    font-size: 20px;
  }
  .v-btn--icon.v-size--default .v-icon,
  .v-btn--fab.v-size--default .v-icon {
    height: 15px;
    font-size: 15px;
    width: 15px;
  }
}
@media screen and (min-width: 600px) and (max-width: 700px) {
  ::v-deep .preview_class.v-dialog {
    max-width: 60%;
    max-height: 90%;
    width: 300px;
    height: 370px;
  }
  .announcement_imgClass {
    width: 250px;
    height: 280px;
  }
  ::v-deep .custom_chk .theme--light.v-label {
    color: #ffffff;
    font-size: 14px;
  }
}
@media screen and (min-width: 701px) and (max-width: 769px) {
  ::v-deep .preview_class.v-dialog {
    max-width: 60%;
    max-height: 90%;
    width: 325px;
    height: 430px;
  }
  .announcement_imgClass {
    width: 280px;
    height: 315px;
  }
  ::v-deep .custom_chk .theme--light.v-label {
    color: #ffffff;
    font-size: 14px;
  }
}
@media screen and (min-width: 770px) and (max-width: 1025px) {
  ::v-deep .preview_class.v-dialog {
    max-width: 70%;
    max-height: 100%;
    width: 370px;
    height: 460px;
    margin-bottom: 0;
    margin-top: 0;
  }
  ::v-deep .announcement_imgClass {
    width: 320px;
    height: 375px;
  }
}
@media screen and (min-width: 1026px) and (max-width: 1440px) {
  ::v-deep .preview_class.v-dialog {
    max-width: 70%;
    max-height: 100%;
    width: 380px;
    height: 525px;
    margin-bottom: 0;
    margin-top: 0;
  }
  .announcement_imgClass {
    width: 340px;
    height: 440px;
  }
}
@media screen and (min-width: 1441px) and (max-width: 2560px) {
  ::v-deep .preview_class.v-dialog {
    max-width: 70%;
    max-height: 100%;
    width: 450px;
    height: 687px;
    margin-bottom: 0;
    margin-top: 0;
  }
  .announcement_imgClass {
    width: 400px;
    height: 587px;
  }
}
.image-container {
  position: relative;
  width: 100%;
}
.card.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: unset !important;
}
</style>