<template>
  <div>
    <!-- New Add Design -->
    <v-dialog v-model="dialog" width="1160" persistent scrollable>
      <v-card class="rounded-lg">
        <v-card-title
          style="font-size: 20px; color: #363636; border: 1px solid #c0c0c0"
          class="pb-4"
        >
          <div>
            <v-icon class="mr-1" style="padding-bottom: 3px"
              >mdi-plus-box-outline</v-icon
            >
            {{ $t("AddCategory") }}
          </div>
          <v-spacer></v-spacer>
          <div>
            <v-btn
              icon
              @click="close(), $refs.form.resetValidation()"
              color="#424242"
              style="border-radius: 8px !important"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row class="mt-5">
              <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                <v-col cols="12">
                  <p style="color: #424242; font-size: 16px">
                    {{
                      $t("createdateM")
                    }}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {{ this.createdate }} {{ $t("By") }} {{ this.fullName }}
                  </p>
                </v-col>
                <v-col cols="12">
                  <label for="title" class="font-style">
                    {{ $t("title") }}
                    <span style="color: red">*</span>
                  </label>
                  <v-text-field
                    :rules="titleRules"
                    outlined
                    counter="50"
                    maxlength="50"
                    dense
                    hide-details="auto"
                    v-model="library.title"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <label for="description" class="font-style">{{
                    $t("description")
                  }}</label>
                  <v-text-field
                    outlined
                    dense
                    hide-details="auto"
                    v-model="library.description"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-row>
                    <v-col class="my-auto" style="padding-top: 2rem">
                      <label
                        for="sort"
                        style="
                          font-size: 16px;
                          color: #424242;
                          margin-right: 10px;
                          margin-top: 5px;
                        "
                        >{{ $t("sort") }}</label
                      >
                      <v-menu open-on-hover offset-y min-width="55px">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            width="55"
                            height="30"
                            color="#47484b"
                            class="text-capitalize mt-1 sort_Btn"
                            outlined
                            v-bind="attrs"
                            v-on="on"
                          >
                            {{ sort }}
                            <v-icon
                              style="margin-left: 0px; margin-right: -0.6rem"
                              >mdi-menu-down</v-icon
                            >
                          </v-btn>
                        </template>
                        <v-list
                          :style="
                            sortNumberItem.length == 1
                              ? { height: '69px' }
                              : sortNumberItem.length == 2
                              ? { height: '103px' }
                              : sortNumberItem.length == 3
                              ? { height: '136px' }
                              : sortNumberItem.length == 4
                              ? { height: '170px' }
                              : { height: '205px' }
                          "
                          class="overflow-y-auto"
                        >
                          <v-list-item
                            v-for="(temp, index) in sortNumberItem"
                            :key="index"
                            @click="sort = temp.value"
                          >
                            <v-list-item-title class="custom_list_item">{{
                              temp.value
                            }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-col>
                    <v-col
                      class="my-auto"
                      style="align-items: right !important; padding-top: 2.1rem"
                    >
                      <v-radio-group row v-model="library.status">
                        <v-radio
                          :label="$t('active')"
                          value="1"
                          color="#67AB30"
                        ></v-radio>
                        <v-radio
                          :label="$t('inactive')"
                          value="0"
                          color="#67AB30"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </v-col>
              </v-col>
              <v-progress-circular
                v-if="loading"
                indeterminate
                class="loading-circle"
                color="green"
              ></v-progress-circular>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                <v-row style="margin-top: 3rem">
                  <v-col cols="12" xs="12" sm="12" md="6" lg="7">
                    <p
                      class="text-left font-style"
                      :style="fileError ? { color: 'red' } : {}"
                    >
                      {{ $t("Image Cover") }}
                    </p>
                    <div class="drop">
                      <v-card
                        width="290"
                        height="215"
                        style="
                          border: 4px dashed #c0c0c0;
                          box-shadow: unset;
                          background: #f8f8f8 0% 0% no-repeat padding-box;
                        "
                        @click="$refs.file.click()"
                        @dragover.prevent
                        @dragenter.prevent
                        @drop.prevent="dropImg($event)"
                      >
                        <v-img
                          :src="library.file"
                          @click="$refs.file.click()"
                          @mouseover="
                            library.file
                              ? (showEditImgTool = true)
                              : (showEditImgTool = false)
                          "
                          @mouseleave="
                            library.file
                              ? (showEditImgTool = false)
                              : (showEditImgTool = false)
                          "
                          :style="
                            fileError ? { border: '1px solid #FA5252' } : {}
                          "
                          width="240"
                          height="170"
                          style="border-radius: 8px !important"
                          class="mx-auto center mt-4"
                          v-if="library.file"
                        >
                          <v-card-title
                            v-if="showEditImgTool"
                            style="left: 15%; top: 15%; position: absolute"
                          >
                            <v-list
                              class="d-flex flex-column align-start"
                              style="padding: 0; border-radius: 8px"
                            >
                              <v-list-item class="list-item-padding preview">
                                <v-btn
                                  color="#424242"
                                  style="font-size: 14px; width: 100px"
                                  text
                                  class="text-capitalize"
                                  @click.stop="previewimg = true"
                                >
                                  <v-icon left>mdi-eye-outline</v-icon>
                                  {{ $t("preview") }}
                                </v-btn>
                              </v-list-item>

                              <v-list-item class="list-item-padding preview">
                                <v-btn
                                  color="error"
                                  style="font-size: 14px; width: 100px"
                                  text
                                  class="text-capitalize"
                                  @click.stop="library.file = null"
                                >
                                  <v-icon left>mdi-delete-outline</v-icon>
                                  {{ $t("delete") }}
                                </v-btn>
                              </v-list-item>
                            </v-list>
                          </v-card-title>
                        </v-img>

                        <v-img
                          v-if="!library.file"
                          src="@/assets/image_loader.png"
                          width="64"
                          height="52"
                          class="mx-auto center"
                          style="margin-top: 2.5rem"
                          @click="$refs.file.click()"
                        ></v-img>
                        <input
                          type="file"
                          class="d-none"
                          ref="file"
                          accept="image/*"
                          @change="uploadImg($event, 1)"
                        />
                        <p
                          v-show="!library.file"
                          class="text-center mt-3"
                          style="
                            color: #424242;
                            font-size: 14px;
                            opacity: 0.6;
                            margin-bottom: 0;
                          "
                        >
                          {{ $t("DragDropfile") }}
                        </p>
                        <p
                          v-show="!library.file"
                          class="text-center"
                          style="color: #424242; font-size: 14px; opacity: 0.6"
                        >
                          {{ $t("OrBrowseFile") }} JPG"
                        </p>
                      </v-card>
                    </div>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="6" lg="5">
                    <v-row>
                      <v-btn
                        color="#A6CC39"
                        style="
                          font-size: 16px;
                          margin-top: 3rem;
                          float: left;
                          border-radius: 8px;
                          height: 40px;
                          width: 165px;
                        "
                        class="text-capitalize white--text btn_hover_effect"
                        @click="$refs.file.click()"
                      >
                        <v-icon class="mr-1">mdi-plus-box-outline</v-icon>
                        {{ $t("Add Cover") }}
                      </v-btn>
                      <p class="mt-3" style="color: #777777; font-size: 12px">
                        {{ $t("RecommendFormatPNG") }}
                      </p>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="mt-3 pb-4">
          <v-spacer></v-spacer>
          <v-btn
            text
            class="text-capitalize btnfont-style"
            width="100"
            style="
              border: 1px solid #c0c0c0;
              border-radius: 8px;
              color: #424242;
            "
            @click="close(), $refs.form.resetValidation()"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            width="100"
            class="text-capitalize btnfont-style btn_hover_effect"
            style="color: #ffff; border: 1px solid #a6cc39; border-radius: 8px"
            color="#A6CC39"
            @click="submit()"
            >{{ $t("submit") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- cropimage -->
    <v-dialog
      v-model="dialogCrop"
      width="1000px"
      height="800px"
      overflow="hidden"
    >
      <v-card class="rounded-lg" overflow="hidden">
        <v-card-title
          class="white--text pb-4"
          style="background-color: #a6cc39; font-size: 30px"
          >Crop Image</v-card-title
        >
        <v-card-text
          class="pt-12"
          style="height: 550px; padding-left: 4rem; padding-right: 4rem"
        >
          <vue-cropper
            ref="cropper"
            :img="option.img"
            :output-size="option.size"
            :output-type="option.outputType"
            :info="true"
            :full="option.full"
            :can-move="option.canMove"
            :can-move-box="option.canMoveBox"
            :fixed-box="option.fixedBox"
            :original="option.original"
            :auto-crop="option.autoCrop"
            :auto-crop-width="option.autoCropWidth"
            :auto-crop-height="option.autoCropHeight"
            :center-box="option.centerBox"
            :high="option.high"
          ></vue-cropper>
        </v-card-text>
        <v-card-actions class="mt-3 pb-4" style="margin-right: 50px">
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize btnfont-style"
            style="
              border: 1px solid #c0c0c0;
              border-radius: 8px;
              color: #424242;
            "
            text
            @click="CancelCrop()"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="text-capitalize btnfont-style btn_hover_effect"
            style="color: #ffff; border: 1px solid #a6cc39; border-radius: 8px"
            color="#A6CC39"
            width="100"
            @click="CropImage()"
            >{{ $t("submit") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- preview image -->
    <v-dialog
      v-model="previewimg"
      persistent
      width="800px"
      height="800px"
      overflow="hidden"
      content-class="elevation-0 preview_class"
    >
      <v-card overflow="hidden" color="transparent">
        <v-card-title style="padding-right: 0 !important">
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="previewimg = false"
            color="#FFFFFF"
            style="border-radius: 8px !important; border: 1px solid #ffffff30"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-img
          width="800px"
          height="500px"
          style="border-radius: 8px; margin: auto"
          :src="library.file"
        ></v-img>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { VueCropper } from "vue-cropper";
import * as moment from "moment/moment";
export default {
  components: {
    VueCropper,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    sortNumberItem: [],
    fullName: String,
  },
  computed: {
    dialog: {
      get() {
        return this.show;
      },
      set() {
        this.$emit("close");
      },
    },
    titleRules() {
      return [(v) => !!v || "Title is required"];
    },
    // descriptionRules() {
    //   return [(v) => !!v || "Description is required"];
    // },
  },
  data: () => ({
    previewimg: false,
    showEditImgTool: false,
    createdate: moment(new Date().toISOString().substr(0, 10))
      .local()
      .format("DD MMM YYYY"),
    sort: null,
    //sortNumberItems: [],
    valid: true,
    dialogCrop: false,
    fileError: false,
    loading: false,
    option: {
      img: "",
      size: 1,
      full: false,
      outputType: "png",
      canMove: true,
      fixedBox: true,
      original: false,
      canMoveBox: false,
      autoCrop: true,
      autoCropWidth: 800,
      autoCropHeight: 450,
      centerBox: false,
      high: true,
    },
    library: {
      title: "",
      description: "",
      file: "",
      fileName: "",
      fileUrl: "",
      status: "1",
    },
  }),
  watch: {
    "library.fileUrl": function () {
      this.fileError = false;
    },
  },
  methods: {
    dropImg(e) {
      let file = e.dataTransfer.files[0];
      if (!/(gif|jpg|jpeg|png|bmp|GIF|JPG|PNG)$/.test(file.name)) {
        alert("Please choose image!");
        return false;
      }

      this.library.fileName = file.name;
      let reader = new FileReader();
      reader.onload = (f) => {
        let src;
        if (typeof f.target.result === "object") {
          src = window.URL.createObjectURL(new Blob([f.target.result]));
        } else {
          src = f.target.result;
        }
        this.option.img = src;
        this.dialogCrop = true;
      };
      reader.readAsArrayBuffer(file);
    },
    uploadImg(e, num) {
      let self = this;
      let file = e.target.files[0];
      if (!/\.(gif|jpg|jpeg|png|bmp|GIF|JPG|PNG)$/.test(e.target.value)) {
        alert("Please choose image!");
        return false;
      }
      this.library.fileName = file.name;
      let reader = new FileReader();
      reader.onload = function (f) {
        let data;
        if (typeof f.target.result === "object") {
          data = window.URL.createObjectURL(new Blob([f.target.result]));
        } else {
          data = f.target.result;
        }
        if (num === 1) {
          self.option.img = data;
          self.dialogCrop = true;
        }
      };
      reader.readAsArrayBuffer(file);
    },
    CancelCrop() {
      this.dialogCrop = false;
    },
    CropImage() {
      this.$refs.cropper.getCropData((data) => {
        this.getFile(data);
        this.dialogCrop = false;
      });
    },
    getFile(e) {
      this.library.fileUrl = e;
      this.library.file = e;
      this.showEditImgTool = false;
    },

    imageTobase64(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.library.file = e.target.result;
      };
      reader.readAsDataURL(fileObject);
    },

    close() {
      this.dialog = false;
      this.library = {
        title: "",
        description: "",
        file: "",
        fileName: "",
        fileUrl: "",
      };
      this.sort = null;
      this.$refs.form.resetValidation();
      this.$refs.file.value = "";
    },

    async submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        if (this.library.fileUrl) {
          const userdata = JSON.parse(
            localStorage.getItem("vivek_authenticated_user_data")
          );
          const memberid = userdata.memberId;
          const res = await this.$axios.post(`${this.web_url}Topic/AddTopic`, {
            topicName: this.library.title,
            pictureBackground: {
              base64: this.library.file,
              fileName: this.library.fileName,
            },
            iconimage: "",
            description: this.library.description,
            companyId: localStorage.getItem("companyID"),
            status: this.library.status == 1 ? true : false,
            sort: this.sort != null && this.sort != "" ? this.sort : 0,
            createBy: memberid,
          });
          if (res.data.status == 0) {
            this.library = {};
            this.$refs.file.value = null;
            this.$emit("confirm");
          }
          this.fileError = false;
          this.close();
        } else {
          this.fileError = true;
          alert("Please attach a picture!");
        }
      } else {
        this.fileError = true;
      }
      this.loading = false;
    },
  },
};
</script>
<style scoped>
/* For Sort menu. */
::v-deep .sort_Btn.v-btn:not(.v-btn--round).v-size--default {
  min-width: 50px;
  font-size: 18px;
  padding: 0 0;
  border: 1px solid #c0c0c0;
  opacity: 1 !important;
}
::v-deep .v-list.overflow-y-auto.v-sheet.theme--light::-webkit-scrollbar {
  width: 7px !important;
}
/* Track */
::v-deep .v-list.overflow-y-auto.v-sheet.theme--light::-webkit-scrollbar-track {
  width: 6px !important;
  background: #e6e6e6;
  border-left: 1px solid #dadada;
}
/* Handle */
::v-deep .v-list.overflow-y-auto.v-sheet.theme--light::-webkit-scrollbar-thumb {
  background: #b0b0b0;
  border: solid 2px #e6e6e6;
  border-radius: 7px;
}
::v-deep .v-list.overflow-y-auto.v-sheet.theme--light {
  padding-top: 0 !important;
  padding-left: 0 !important;
  padding-bottom: 0 !important;
}
::v-deep .v-list-item {
  min-height: 32px;
  /* border-bottom: 1px solid #707070; */
  border-top: 2px solid #707070;
}
::v-deep .v-list-item:hover {
  background-color: #707070 !important;
}
::v-deep
  .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled):hover {
  color: #ffffff !important;
}
/* end sort menu. */

/*previous css */
.loading-circle {
  position: fixed;
  z-index: 5;
  right: 50%;
  bottom: 50%;
  transform: translate(-50%, -50%);
}
::v-deep .v-dialog {
  border-radius: 10px !important; /**35px */
}
::v-deep .v-text-field__slot {
  font-size: 16px !important;
}
.font-style {
  font-size: 16px;
  color: #424242;
}
.btnfont-style {
  font-size: 20px;
}
::v-deep .v-input__slot {
  border-radius: 10px !important;
}
</style>

<style scoped>
.list-item-padding.v-sheet.v-list {
  border-radius: 8px !important;
}
.list-item-padding.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled):hover {
  border-radius: 8px !important;
}
.list-item-padding.list-item-padding.theme--light {
  padding: 0 5px !important;
  min-height: 42px !important;
}
::v-deep .list-item-padding.v-list-item:hover {
  background-color: unset !important;
}
.btn_hover_effect,
.reset-btn {
  color: #fff;
  width: 80%;
  font-size: 18px;
  z-index: 0;
  position: relative;
  display: inline-block;
}
.btn_hover_effect:before,
.reset-btn:before {
  background-color: #4fb14e;
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  opacity: 0.5 !important;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}
.btn_hover_effect:hover:before,
.reset-btn:hover:before {
  background-color: #4fb14e;
  top: 0;
}
.btn_hover_effect:hover,
.reset-btn:hover {
  transition: 0.25s;
}
.btn_hover_effect:after,
.reset-btn:after {
  position: absolute;
  right: 2.34375rem;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(50%);
  -ms-transform: translateY(-50%) translateX(50%);
  transform: translateY(-50%) translateX(50%);
}
.close_icon_effect:hover {
  background: #32323200 0% 0% no-repeat padding-box;
  background-color: rgb(66, 66, 66, 0.07) !important;
}
::v-deep .list-item-padding.preview.v-list-item.theme--light {
  border-top: unset !important;
}
::v-deep .primary--text {
  color: #a6cc39 !important;
}
</style>
