<template>
  <div>
    <v-dialog width="600" v-model="dialog" persistent>
      <v-card class="mx-auto py-3" style="border-radius: 8px">
        <v-card-title>
          <p>{{ $t("cookiesandprivacy") }}</p>
        </v-card-title>
        <v-card-text>
          <p style="font-size:15px;">{{ $t("firstparagraph") }}</p>
          <p style="font-size:15px;">{{ $t("secparagraph") }}</p>
          <v-row class="px-3">
            <span style="font-weight: bold;">{{ $t("morepolicy") }}</span>
            &nbsp;
            <span
              @click="privacydialog = true"
              style="font-weight: bold;cursor:pointer;font-size:15px;"
              class="text-decoration-underline"
            >
              {{ $t("privacypolicy") }}
            </span>
            &nbsp;
            <p style="font-weight: bold;font-size:15px;">{{ $t("and") }}</p>
            &nbsp;
            <span
              @click="cookiesdialog = true"
              style="font-weight: bold;cursor:pointer;font-size:15px;"
              class="text-decoration-underline"
            >
              {{ $t("cookiepolicy") }}
            </span>
          </v-row>

          <v-row class="my-6 justify-center">
            <v-checkbox class="mt-0" v-model="chkAccept" color="#a6cc39" hide-details />
            <p class="mt-1" style="color: #47484b; font-size: 16px; margin-bottom:0px">Accept</p>
          </v-row>

          <v-row class="px-3 justify-space-around" >
            <v-spacer></v-spacer>
            <v-btn
              class="mr-3"
              outlined
              small
              @click="Later()"
              style="text-transform:none;color:#000;font-size:15px; width: 150px;"
              >Later</v-btn
            >
            <v-btn
              @click="UpdatePolicy()"
              small
              color="#a6cc39"
              :disabled="chkAccept==false?true:false"
              style="text-transform:none;color:white;font-size:16px; width: 150px;"
            >
              Submit
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog width="600" v-model="privacydialog" persistent>
      <v-card class="mx-auto py-3" style="border-radius: 8px">
       
          <v-card-title>
            <v-spacer></v-spacer>
            <v-icon @click="privacydialog = false">mdi-close</v-icon>
          </v-card-title>
          <v-card-text>
            <div class="th">
              <h3 style="text-align:center;">
                นโยบายคุ้มครองข้อมูลส่วนบุคคล 
              </h3>
              <h4 style="text-align:center;">
                บริษัท ฟิวชั่น โซลูชั่น จำกัด
              </h4>
              <p>
                บริษัท ฟิวชั่น โซลูชั่น จำกัด
                ให้ความสำคัญในความเป็นส่วนตัวและการคุ้มครองข้อมูลส่วนบุคคล
                ของลูกค้า คู่ค้า พันธมิตรทางธุรกิจ โดย บริษัท ฟิวชั่น โซลูชั่น
                จำกัด มีความประสงค์จะปกป้องข้อมูลส่วน
                บุคคลจากการถูกนำไปใช้ในทางที่ไม่ถูกต้อง ไม่เป็นไปตามกฎหมาย
                ระเบียบ ข้อบังคับ
                และจะดำเนินการเพื่อรักษาข้อมูลส่วนบุคคลให้ปลอดภัยตามกฎหมาย
                จึงได้จัดทำนโยบายฉบับนี้ขึ้นดังมีข้อความต่อไปนี้
              </p>
              <div>
                <h4>1. คำนิยาม</h4>
                <span>
                  &quot;บริษัท&quot;หมายถึง บริษัท ฟิวชั่น โซลูชั่น จำกัด
                  &quot;ลูกค้า&quot; หมายถึง ผู้ซื้อหรือผู้ใช้บริการต่าง ๆ
                  ของบริษัท รวมถึงการใช้บริการเว็บไซต์
                  หรือแอพพลิเคชั่นหรือบริการอื่น ๆ ของบริษัท
                  และให้หมายความรวมถึง คู่ค้าธุรกิจ พันธมิตรทางธุรกิจ
                  และผู้มีส่วนได้ส่วนเสียด้วย &quot;เว็บไซต์&quot; หมายถึง
                  เว็บไซต์
                </span>
                <a href="https://www.seedkm.com/" style="color:#1864d6;"
                  >https://www.seedkm.com/</a
                >
                <span>
                  เว็บไซต์ ซึ่งบริษัทเป็นเจ้าของหรือให้บริการ
                  &quot;แอปพลิเคชั่น&quot; หมายถึง
                  แอปพลิเคชันซึ่งบริษัทให้บริการ &quot;ผู้ควบคุมข้อมูล&quot;
                  หมายถึง บริษัทและบุคคลที่บริษัทแต่งตั้งให้เป็นผู้ควบคุมข้อมูล
                  &quot;เจ้าหน้าที่คุ้มครอง&quot; หมายถึง
                  เจ้าหน้าที่ซึ่งแต่งตั้งโดยผู้ควบคุมข้อมูลเพื่อทำหน้าที่เป็นเจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคลตามกฎหมายคุ้มครองข้อมูลส่วนบุคคล
                  &quot;ข้อมูลส่วนบุคคล&quot; หมายถึง
                  ข้อมูลเกี่ยวกับบุคคลซึ่งทำให้สามารถระบุตัวบุคคลนั้นได้ไม่ว่าทางตรงหรือทางอ้อม
                  ตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562
                  และกฎหมายว่าด้วยการคุ้มครองข้อมูลส่วนบุคคล
                  &quot;ผู้ประมวลผลข้อมูล&quot; หมายถึง
                  ผู้ประมวลผลข้อมูลส่วนบุคคลให้แก่บริษัท
                </span>
              </div>
              <br />
              <div>
                <h4>2.บททั่วไป</h4>
                <p>
                  นโยบายคุ้มครองข้อมูลส่วนบุคคลฉบับนี้จัดทำขึ้นเพื่อชี้แจงรายละเอียดและวิธีการคุ้มครองและจัดการข้อมูลส่วนบุคคลของลูกค้า
                  โดยบริษัทอาจดำเนินการปรับปรุงหรือแก้ไขนโยบายคุ้มครองข้อมูลส่วนบุคคลนี้
                  รวมถึงที่ได้กำหนดไว้โดยเฉพาะเจาะจงอยู่ในส่วนใดส่วนหนึ่งของเว็บไซต์หรือแอปพลิเคชันนี้ไม่ว่าบางส่วนหรือทั้งหมดเป็นครั้งคราว
                  เพื่อให้สอดคล้องกับแนวทางการให้บริการและหลักเกณฑ์ของกฎหมายที่มีการเปลี่ยนแปลงไป
                  ดังนั้น
                  ลูกค้าจึงควรติดตามนโยบายคุ้มครองข้อมูลส่วนบุคคลที่กำหนดไว้นี้อยู่เสมอ
                  นโยบายคุ้มครองข้อมูลส่วนบุคคลนี้มีขึ้นเพื่อใช้กับ<br />1.
                  การขายสินค้าหรือบริการ<br />2. การลงทะเบียนสมัครใช้บริการ
                  website หรือ Application <br />
                  3. การใช้บริการหรือซื้อสินค้า การเข้าถึงและใช้เนื้อหา ฟีเจอร์
                  เทคโนโลยี
                  หรือฟังก์ชันที่ปรากฏในเว็บไซต์นี้หรือแอปพลิเคชันกับบริษัท<br />4.
                  บริการอื่นๆ ที่เกี่ยวข้อง รวมถึงการให้บริการอื่นๆ ของบริษัท
                  ทั้งที่มีอยู่ในปัจจุบันและที่บริษัทจะได้พัฒนาหรือจัดให้มีขึ้นในอนาคต
                </p>
              </div>
              <div>
                <h4>
                  3. วิธีการเก็บรวบรวมข้อมูลและข้อมูลที่จัดเก็บ
                </h4>
                <p>
                  บริษัทจะเก็บรวบรวมข้อมูลส่วนบุคคล ด้วยวิธีการต่าง ๆ
                  รวมถึงใช้เทคโนโลยีต่าง ๆ เช่น คุกกี้
                  ซึ่งเป็นชิ้นส่วนข้อมูลเล็ก ๆ
                  ที่เก็บอยู่ในอุปกรณ์ของลูกค้าที่จะทำให้เว็บไซต์หรือแอปพลิเคชันสามารถจดจำข้อมูลการเข้าถึงเว็บไซต์หรือแอปพลิเคชัน
                  หรือวิธีที่ลูกค้าใช้งานเว็บไซต์หรือแอปพลิเคชันในแต่ละครั้ง
                  โดยข้อมูลที่เกี่ยวข้องกับลูกค้าที่บริษัทจัดเก็บประกอบไปด้วย
                </p>
                <p>
                  3.1. ข้อมูลที่ลูกค้าให้ไว้โดยตรง
                  บริษัทจำเป็นต้องเก็บข้อมูลส่วนบุคคลของลูกค้าเพื่อใช้ในการให้บริการแก่ลูกค้าหรือต้องปฏิบัติตามสัญญาหรือตามกฎหมาย
                  โดยจะรวบรวมข้อมูลที่ลูกค้าส่งให้กับบริษัท อาทิ ชื่อ-นามสกุล
                  ที่อยู่ วัน/เดือน/ปี เกิด เพศ อายุ รูปถ่าย อีเมล
                  เลขที่บัญชีธนาคาร หมายเลขบัตรเครดิต (ถ้ามี)
                  หมายเลขบัตรประจำตัวประชาชน เลขประจำตัวผู้เสียภาษีอากร
                  หมายเลขโทรศัพท์ รวมถึง ข้อมูลเกี่ยวกับบัญชีผู้ใช้งาน อีเมล
                  Social network : Line หรือ Facebook รูปถ่าย ความสนใจ การงาน
                  ลายมือชื่อ และความเห็นทุกอย่างที่ลูกค้าได้แสดงผ่านเว็บไซต์
                  สำหรับข้อมูลที่เป็นข้อมูลอ่อนไหว
                  บริษัทจะจัดเก็บเพียงเท่าที่จำเป็น
                  เมื่อได้รับความยินยอมจากลูกค้าโดยชัดแจ้งแล้วเท่านั้น
                </p>
                <p>
                  3.2. ข้อมูลที่ได้รับจากการใช้บริการของลูกค้า
                  บริษัทจะรวบรวมข้อมูลเกี่ยวกับบริการที่ลูกค้าใช้และวิธีการใช้งานของลูกค้า
                  ซึ่งข้อมูลเหล่านี้รวมถึงข้อมูลภาพและเสียง
                  ข้อมูลอุปกรณ์ที่ลูกค้าใช้สำหรับการเข้าใช้งานเว็บไซต์หรือแอปพลิเคชัน
                  ข้อมูลจราจรทางคอมพิวเตอร์ (Log)
                  ข้อมูลการติดต่อสื่อสารระหว่างลูกค้าและผู้ใช้งานรายอื่น
                  และข้อมูลจากการบันทึกการใช้งาน สถิติการเข้าเว็บไซต์
                  เวลาที่เยี่ยมชมเว็บไซต์ (Access Time) ข้อมูลที่ลูกค้าค้นหา
                  การใช้ฟังก์ชันต่าง ๆ ในเว็บไซต์
                  และข้อมูลที่บริษัทได้เก็บรวบรวมผ่านคุกกี้หรือเทคโนโลยีอื่นที่คล้ายกัน
                  สำหรับข้อมูลส่วนบุคคลที่กฎหมายระบุให้ต้องขอความยินยอมจากลูกค้าก่อนทำการเก็บรวบรวม
                  บริษัทจะเก็บรวบรวมเพียงเท่าที่จำเป็น
                  เมื่อได้รับความยินยอมจากลูกค้า
                  เว้นแต่เป็นกรณีที่มีข้อยกเว้นตามกฎหมายให้บริษัทสามารถเก็บรวบรวมได้โดยไม่ต้องขอความยินยอมจากลูกค้า
                </p>
              </div>
              <div>
                <h4>
                  4. ระยะเวลาในการจัดเก็บข้อมูล
                </h4>
                <p>
                  บริษัทอาจจะเก็บรวบรวมข้อมูลส่วนบุคคลของลูกค้าเฉพาะข้อมูลที่จำเป็น
                  และการจัดเก็บรวบรวมในระยะเวลานานเท่าที่จำเป็น
                  ซึ่งปัจจุบันบริษัทกำหนดระยะเวลาการจัดเก็บสูงสุดไว้ที่ 10 ปี
                  หลังจากลูกค้าเลิกใช้บริการหรือสิ้นสุดสัญญากับบริษัท
                  บริษัทจะดำเนินการทำลายข้อมูลดังกล่าว
                </p>
              </div>
              <div>
                <h4>
                  5 . วัตถุประสงค์การเก็บ การใช้งานข้อมูลส่วนบุคคล
                </h4>
                <p>
                  5.1
                  เพื่อให้การซื้อสินค้าและการใช้บริการเป็นไปด้วยความเรียบร้อยและสอดคล้องกับกฎหมาย
                  หลักเกณฑ์ และระเบียบต่าง ๆ ที่เกี่ยวข้อง<br />
                  5.2
                  เพื่อประโยชน์ในการยืนยันหรือระบุตัวตนของลูกค้าเมื่อเข้าใช้งานบริการต่าง
                  ๆ
                  และเพื่อประโยชน์ในการยืนยันหรือระบุตัวตนของลูกค้าเมื่อเข้าใช้งานบริการต่างๆ
                  และการให้บริการแก่ลูกค้า<br />
                  5.3 เพื่อตรวจสอบข้อมูลการใช้บริการของลูกค้า
                  ตามมาตรฐานความปลอดภัยและความมั่นคงของระบบในการใช้บริการ
                  การจัดการและการคุ้มครองโครงสร้างพื้นฐานทางเทคโนโลยีสารสนเทศ
                  <br />
                  5.4 เพื่อติดต่อลูกค้า ผ่านทาง Social network โทรศัพท์ ข้อความ
                  (SMS) อีเมล(E-mail) หรือไปรษณีย์ หรือผ่านช่องทางใด ๆ
                  เพื่อสอบถาม หรือแจ้งให้ลูกค้าทราบ
                  หรือตรวจสอบและยืนยันข้อมูลเกี่ยวกับบัญชีของลูกค้า
                  หรือสำรวจความคิดเห็น
                  หรือแจ้งข้อมูลข่าวสารอื่นใดที่เกี่ยวข้องกับการให้บริการของบริษัทตามที่จำเป็น
                  <br />
                  5.5 เพื่อประมวลผล
                  วิเคราะห์ประโยชน์อื่นใดที่เกี่ยวข้องกับการดำเนินธุรกิจของบริษัท
                  <br />
                  5.6 เพื่อพัฒนาผลิตภัณฑ์ บริการ
                  และเพิ่มประสิทธิภาพในการให้บริการงานด้านต่าง ๆ
                  แก่ลูกค้ามากยิ่งขึ้น
                  <br />
                  5.7 เพื่อป้องกันหรือระงับอันตรายต่อ ชีวิต ร่างกาย
                  หรือสุขภาพของลูกค้า รวมถึงทรัพย์สินของลูกค้า
                  หรือเป็นการจำเป็นเพื่อการปฏิบัติหน้าที่เพื่อประโยชน์สาธารณะของบริษัท
                  หรือปฏิบัติหน้าที่ในการใช้อำนาจรัฐที่ได้มอบให้แก่บริษัทหรือลูกจ้างหรือผู้แทนของบริษัท
                  หรือเป็นการปฏิบัติตามกฎหมาย
                </p>
              </div>
              <div>
                <h4>
                  6. การเปิดเผยข้อมูลส่วนบุคคลต่อบุคคลภายนอก
                </h4>
                <p>
                  บริษัทจะไม่เปิดเผยข้อมูลส่วนบุคคลของลูกค้าต่อบุคคลใด ๆ
                  โดยปราศจากการยินยอมจากลูกค้า ไม่ว่ากรณีใดทั้งสิ้น เว้นแต่
                  ต้องเปิดเผยตามที่กฎหมายระบุไว้เท่านั้น
                  ในกรณีที่ลูกค้าเชื่อว่าบุคคลที่บริษัทเปิดเผยข้อมูลส่วนบุคคลของลูกค้าตามข้างต้น
                  ได้มีการนำข้อมูลส่วนบุคคลของลูกค้าไปใช้เพื่อวัตถุประสงค์อื่นนอกเหนือจากขอบเขตที่บริษัทได้กำหนดไว้
                  ลูกค้าสามารถแจ้งบริษัทเพื่อดำเนินการในส่วนเกี่ยวข้องต่อไป
                  ทั้งนี้
                  บริษัทจะเปิดเผยข้อมูลส่วนบุคคลของลูกค้าภายใต้หลักเกณฑ์ที่กฎหมายกำหนด
                  เช่น การเปิดเผยข้อมูลต่อหน่วยงานราชการ หน่วยงานภาครัฐ
                  หน่วยงานที่มีหน้าที่กำกับดูแลการให้บริการ
                  หรือหน่วยงานกำกับดูแลลูกค้า
                  รวมถึงในกรณีที่มีการร้องขอให้เปิดเผยข้อมูลโดยอาศัยอำนาจตามกฎหมาย
                  อาทิ การร้องขอข้อมูลเพื่อการฟ้องร้องหรือดำเนินคดีตามกฎหมาย
                  หรือเป็นการร้องขอจากหน่วยงานเอกชน หรือบุคคลภายนอกอื่น ๆ
                  ที่มีความเกี่ยวข้องกับกระบวนการทางกฎหมาย
                </p>
              </div>
              <div>
                <h4>
                  7. สิทธิของเจ้าของข้อมูลส่วนบุคคล
                </h4>
                <p>
                  7.1
                  เจ้าของข้อมูลมีสิทธิได้รับการแจ้งให้ทราบถึงวิธีการขั้นตอนการจัดเก็บข้อมูล<br />7.2
                  สิทธิในการขอเข้าถึงข้อมูลส่วนบุคคล<br />7.3
                  สิทธิในการคัดค้านการเก็บรวบรวม ใช้
                  หรือเปิดเผยข้อมูลส่วนบุคคล<br />
                  7.4 สิทธิในการขอให้ลบหรือทำลาย
                  หรือทำให้ข้อมูลส่วนบุคคลเป็นข้อมูลที่ไม่สามารถระบุตัวบุคคล
                </p>
              </div>
              <div>
                <h4>
                  8. การเข้าถึงและการปรับปรุงข้อมูลส่วนบุคคล
                </h4>
                <p>
                  8.1
                  ในกรณีที่ลูกค้าไม่ประสงค์จะรับข้อมูลและข่าวสารประชาสัมพันธ์จากบริษัทโปรดแจ้งความประสงค์
                  ได้ที่ pdpa@fusionsol.com<br />
                  8.2 ในกรณีที่ลูกค้ามีความประสงค์จะแก้ไขข้อมูลส่วนบุคคล
                  ระงับการใช้ข้อมูลส่วนบุคคล คัดค้านการเก็บรวบรวม ใช้
                  หรือเปิดเผยข้อมูลส่วนบุคคล คัดค้านการประมวลผลข้อมูลส่วนบุคคล
                  ดำเนินการลบข้อมูลส่วนบุคคลออกจากระบบ
                  ถอนความยินยอมที่เคยให้แก่บริษัทในการเก็บรวบรวม ใช้
                  หรือเปิดเผยข้อมูลส่วนบุคคล หรือ มีข้อขัดข้องใด ๆ
                  เกี่ยวกับการดังกล่าว สามารถติดต่อได้ที่ pdpa@fusionsol.com<br />
                  8.3 ในกรณีที่ลูกค้าประสงค์จะขอรับทราบความมีอยู่
                  ลักษณะของข้อมูลส่วนบุคคล
                  วัตถุประสงค์ของการนำข้อมูลส่วนบุคคลของลูกค้าไปใช้ หรือ
                  ขอให้บริษัทเปิดเผยถึงการได้มาซึ่งข้อมูลส่วนบุคคล
                  สามารถติดต่อได้ที่เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล E-mail:
                  pdpa@fusionsol.com
                  บริษัทจะพิจารณาและแจ้งผลการพิจารณาตามคำร้องขอภายใน 30
                  วันนับแต่วันที่บริษัทได้รับคำร้องขอดังกล่าว อย่างไรก็ตาม
                  บริษัทสามารถปฏิเสธการใช้สิทธิของลูกค้าได้ภายใต้เงื่อนไขตามที่กฎหมายกำหนด
                  ทั้งนี้ หากบริษัทไม่สามารถดำเนินการตามคำขอของลูกค้าได้
                  บริษัทจะทำการบันทึกการปฏิเสธคำขอพร้อมด้วยเหตุผลไว้
                </p>
              </div>
              <div>
                <h4 style="font-weight: bold">
                  9. การเชื่อมโยงไปยังเว็บไซต์ แอปพลิเคชัน
                  ผลิตภัณฑ์และบริการของบุคคลภายนอก
                </h4>
                <p>
                  เว็บไซต์ของบริษัทอาจมีลิ้งก์เชื่อมโยงไปยังเว็บไซต์ ผลิตภัณฑ์
                  และบริการของบุคคลภายนอก
                  ซึ่งบุคคลภายนอกเหล่านั้นอาจเก็บรวบรวมข้อมูลบางอย่างเกี่ยวกับการใช้บริการของลูกค้า
                  โดยบริษัทไม่สามารถรับผิดชอบในความปลอดภัยหรือความเป็นส่วนตัวของข้อมูลใด
                  ๆ ของลูกค้าที่เก็บรวบรวมโดยเว็บไซต์ ผลิตภัณฑ์
                  หรือบริการของบุคคลภายนอกดังกล่าว
                  ลูกค้าควรใช้ความระมัดระวังและตรวจสอบนโยบายคุ้มครองข้อมูลส่วนบุคคลของเว็บไซต์
                  ผลิตภัณฑ์ และบริการของบุคคลภายนอกเหล่านั้นด้วย
                </p>
              </div>
              <div>
                <h4>
                  10. มาตรการความมั่นคงปลอดภัยในการเก็บรักษาข้อมูลส่วนบุคคล
                </h4>
                <p>
                  บริษัทให้ความสำคัญกับความปลอดภัยของข้อมูลส่วนบุคคลที่เกี่ยวกับหรืออ้างอิงถึงลูกค้าอย่างจริงจัง
                  บริษัทมีมาตรการความปลอดภัยที่เหมาะสมเพื่อป้องกันไม่ให้ข้อมูลส่วนบุคคลของลูกค้าสูญหายโดยบังเอิญ
                  ถูกใช้ เข้าถึง เปลี่ยนแปลง หรือเปิดเผยโดยไม่ได้รับอนุญาต
                  บริษัทจำกัดการเข้าถึงข้อมูลส่วนบุคคลของลูกค้าสำหรับพนักงาน
                  ตัวแทน ผู้รับจ้างและบุคคลภายนอกที่มี
                  'ความจำเป็นต้องได้รับข้อมูล
                </p>
              </div>
              <div>
                <h4>
                  11. การใช้บังคับนโยบายคุ้มครองข้อมูลส่วนบุคคล
                </h4>
                <p>
                  นโยบายคุ้มครองข้อมูลส่วนบุคคลนี้มีผลใช้บังคับกับข้อมูลส่วนบุคคลทั้งหมดที่บริษัทเป็นผู้เก็บรวบรวม
                  และลูกค้าตกลงให้บริษัทมีสิทธิในการเก็บ รักษา
                  และนำข้อมูลส่วนบุคคลของลูกค้าที่บริษัทได้รวบรวมไว้แล้ว (หากมี)
                  ตลอดจนข้อมูลส่วนบุคคลของลูกค้าที่บริษัทจัดเก็บในปัจจุบัน
                  และที่จะได้จัดเก็บในอนาคต ไปใช้
                  หรือเปิดเผยแก่บุคคลอื่นภายในขอบเขตตามที่ระบุไว้ในนโยบายคุ้มครองข้อมูลส่วนบุคคลนี้
                </p>
              </div>
              <div>
                <h4>
                  12. ช่องทางการติดต่อ
                </h4>
                <span>
                  บริษัทได้มอบหมายและแต่งตั้งให้ คุณกาญจนา หวังสุข
                  เป็นเจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล
                  โดยให้มีอำนาจและหน้าที่ในฐานะเจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล
                  ตามที่พระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562 กำหนด
                  และเป็นผู้ประสานงานเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคลของบริษัท
                  สถานที่ติดต่อ: บริษัท ฟิวชั่น โซลูชั่น จำกัด 77/148
                  อาคารสินสาธรทาวเวอร์ ชั้น 34 แขวงคลองต้นไทร เขตคลองสาน
                  กรุงเทพมหานคร ในกรณีมีข้อร้องเรียนเกี่ยวกับบริษัท
                  ลูกจ้างหรือพนักงานของบริษัทฝ่าฝืนหรือไม่ปฏิบัติตามกฎหมาย
                  เจ้าของข้อมูลส่วนบุคคลสามารถร้องเรียนต่อหน่วยงานกำกับดูแล
                  ตามรายละเอียดดังนี้ สำนักงานคณะกรรมการคุ้มครองข้อมูลส่วนบุคคล
                  สถานที่ติดต่อ: ชั้น 7 อาคารรัฐประศาสนภักดี
                  ศูนย์ราชการเฉลิมพระเกียรติ 80 พรรษา ถนนแจ้งวัฒนะ
                  แขวงทุ่งสองห้อง เขตหลักสี่ กรุงเทพมหานคร 10210
                  โดยมติคณะกรรมการบริษัท ประกาศ ณ วันที่</span
                ><span style="color:#1864d6"> 09/04/2564</span>
              </div>
              <br />
              <p>(นายศุภกิจ ยงวิทิตสถิต )</p>
              <p>กรรมการ</p>
            </div>
            <div class="en">
              <h3 style="text-align:center;">
                Personal Information Protection Policy
              </h3>
              <h4 style="text-align:center;">
                Fusion Solution Company Limited
              </h4>
              <p>
                Fusion Solution Co., Ltd. places great importance on the privacy
                and protection of personal information of customers, partners,
                business partners. Fusion Solution Co., Ltd. intends to protect
                personal data from people who might misuse it and not comply
                with applicable laws and regulation and will take steps to keep
                your personal information safe by law. Therefore, this policy
                has been made as follows:
              </p>
              <div>
                <h4>1.Definitions</h4>
                <span>
                  "Company" means Fusion Solution Company Limited. 
                  "Customer" means a purchaser or user of the 
                  Company's services, including the use of the website. Or other 
                  applications or services of the Company and shall include 
                  business partners, and stakeholders too. “Website” 
                  means the website
                </span>
                <a href="https://www.seedkm.com/" style="color:#1864d6;"
                  >https://www.seedkm.com/</a
                >
                <span>
                  or the website owned or operated by the Company. “Data Controller” 
                  means a company and a person appointed by the Company as a data controller.
                  "Protection Officer" means the officer appointed by the data controller to act as 
                  the Personal Data Protection Officer under the Personal Data Protection Law.
                  <br />
                  “Personal Information” means information about an 
                  individual that allows that person to be directly or 
                  indirectly identified. According to the Personal Data 
                  Protection Act 2019 and the Personal Data Protection Law 
                  "Data Processor" means the person processor of 
                  personal data to the Company. 
                </span>
              </div>
              <br />
              <div>
                <h4>2.General</h4>
                <p>
                  This Privacy Policy is prepared to explain the details and
                  methods of protecting and handling personal information of
                  customers. The company may update or revise this privacy
                  policy including as specifically set forth in any part of this
                  website or application, either part or all from time to time.
                  In order to comply with changing service guidelines and legal
                  regulations, customers are therefore advised to keep up with
                  this Privacy Policy set out. 
                  <br />
                  This Privacy Policy is intended to apply to <br />
                  1. Sale of goods or services <br />
                  2. Registration to apply for the website service or
                  Application
                  <br />
                  3. Using the service or purchasing products Access and use
                  with Company content, features, technology, or functions found
                  on this website or applications. <br />
                  4. Other related services This includes other services of the
                  Company, both currently available and that the Company will
                  develop or provide in the future. <br />
                </p>
              </div>
              <div>
                <h4>
                  3. Methods for collecting information and what information is
                  collected
                </h4>
                <p>
                  The company will collect personal information. Various methods
                  include using technologies such as cookies, which are small
                  pieces of information stored on a customer's device that
                  enable the website or application to remember access to the
                  website or application. Or the way customers use the website
                  or application each time. The information relating to the
                  customers collected by the company includes:
                </p>
                <p>
                  3.1.Information provided by the customer directly. The company
                  is obliged to collect personal information of customers in
                  order to provide services to customers or to comply with a
                  contract or law. It will collect information that customers
                  send to the company such as name - surname, address, date /
                  month / year of birth, gender, age, photo, email address, bank
                  account number. Credit card number (If any) ID card number Tax
                  Identification Number Telephone numbers, including information
                  about email accounts, Social network: Line or Facebook,
                  photos, interests, work, signature, and all comments that
                  customers have shown on the website For sensitive information
                  The company will store only as needed. With the express
                  consent of the customer only
                </p>
                <p>
                  3.2.Information received from the use of the customer The
                  company will collect information about the services that
                  customers use and how they are used. This includes video and
                  audio data. Device information that the customer uses for
                  accessing the website or application. Computer traffic
                  information (Log) Communication information between customers
                  and other users And information from the usage record Website
                  traffic statistics Time to visit the website (Access Time)
                  Information that the customer searches Using the functions on
                  the website and the information we collect through cookies or
                  other similar technologies.
                </p>
              </div>
              <div>
                <h4>
                  4.Duration of data storage
                </h4>
                <p>
                  The company may collect customers' personal information only
                  necessary information. And to keep collecting for as long as
                  necessary Currently, the company sets the maximum storage
                  period of 10 years after the customer ceases to use the
                  service or terminates the contract with the company. The
                  company will destroy the said information.
                </p>
              </div>
              <div>
                <h4>
                  5. Purpose of Collection Use of personal information
                </h4>
                <p>
                  5.1 To make the purchase of goods and the use of services in
                  an orderly manner and in accordance with relevant laws, rules
                  and regulations. <br />
                  5.2 For the purpose of verifying or identifying the Customer
                  when accessing the Services and for the purpose of verifying
                  or identifying the Customer when accessing the Services. And
                  providing services to customers <br />
                  5.3 To verify customer service usage information In accordance
                  with the safety and security standards of the service
                  Management and Protection of Information Technology
                  Infrastructure <br />
                  5.4 To contact customers via social network, telephone, text
                  message (SMS), e-mail (E-mail) or post, or through any channel
                  to inquire or notify customers. Or check and verify the
                  customer's account information Or poll Or provide any other
                  information related to the service of the company as necessary
                  <br />
                  5.5 to process Analyze any other benefits related to the
                  Company's business operations. <br />
                  5.6 To develop products, services and increase efficiency in
                  providing various services to customers. <br />
                  5.7 To prevent or suppress any harm to life, body or health of
                  the customer. Including the customer's property Or is
                  necessary for the performance of duties for the public
                  interest of the company Or perform duties in exercising the
                  power of the state given to the company or employees or
                  representatives of the company. Or is it legal practice
                </p>
              </div>
              <div>
                <h4>
                  6. Disclosure of personal information to third parties
                </h4>
                <p>
                  The company will not disclose customers' personal information
                  to any person without the customer's consent. In any case,
                  except to disclose only as specified by law. In the event that
                  the customer believes that the person with whom the Company
                  discloses the personal information of the customer above or
                  the customer's personal information is used for purposes other
                  than the scope of the company, the customers can notify the
                  company for further action.
                  <br />In this regard, the company will disclose personal
                  information of customers under the regulations stipulated by
                  law such as disclosure to government agencies Government
                  agencies The agency responsible for governing the service Or
                  customer regulators including in the event of a lawful
                  disclosure request, such as a request for information for
                  prosecution or legal proceedings or is a request from a
                  private agency or other third parties involved in legal
                  processes.
                </p>
              </div>
              <div>
                <h4>
                  7. Rights of Personal Data Subject
                </h4>
                <p>
                  7.1 The data subject has the right to be informed of the
                  methods, collection procedures. <br />
                  7.2 Right to request access to personal information. <br />
                  7.3 Right to object to collection, use or disclosure of
                  personal information. <br />
                  7.4 Right to request erasure or destruction or make personal
                  information non-personally identifiable information.
                </p>
              </div>
              <div>
                <h4>
                  8.Access and update of personal information
                </h4>
                <span
                  >8.1 In the event that the customer does not wish to receive
                  information and press releases from the company, please notify
                  At
                </span>
                <span class="text-decoration-underline" style="color:#1864d6;">
                  pdpa@fusionsol.com</span
                ><br />
                <span
                  >8.2 In the event that the customer wishes to correct the
                  personal information, Suspend the use of personal information,
                  Object to collection, use or disclosure of personal
                  information, Object to processing of personal data, proceed to
                  delete personal information from the system and Withdraw the
                  consent that has been given to the Company for the collection,
                  use or disclosure of personal information, or there is any
                  failure in connection with it. You can contact at</span
                >
                <span class="text-decoration-underline" style="color:#1864d6;">
                  pdpa@fusionsol.com</span
                ><br />
                <span
                  >8.3 In the event that the customer wishes to be informed of
                  the availability, Characteristics of personal information,
                  Purpose of using the customer's personal information or
                  requesting the company to disclose the acquisition of personal
                  information.</span
                ><br />
                <span style="font-weight: bold">
                  Contact the Personal Data Protection Officer E-mail:<span
                    class="text-decoration-underline"
                    style="color:#1864d6;"
                  >
                    pdpa@fusionsol.com
                  </span> </span
                ><br />
                <span
                  >The company will consider and notify the result of
                  consideration of the request within 30 days from the date the
                  company receives such request. <br />
                  However, the company may refuse to exercise the rights of the
                  customer under the conditions stipulated by law. If the
                  company is unable to process the customer's request. The
                  company will record the rejection of the request with reasons.
                </span>
                <br />
              </div>
              <div>
                <h4>
                  9. Links to Third Party Websites, Applications, Products and
                  Services
                </h4>
                <p>
                  The Company's website may contain links to third-party
                  websites, products and services. These third parties may
                  collect certain information about their use of the services by
                  The Company cannot be held responsible for the security or
                  privacy of any customer information collected by such
                  third-party websites, products or services. Customers should
                  exercise caution and review the privacy policies of those
                  third-party websites, products and services.
                </p>
              </div>
              <div>
                <h4>
                  10. Security measures for the storage of personal information
                </h4>
                <p>
                  The company takes the security of personal information about
                  or referring to customers very seriously. The company has
                  appropriate security measures to prevent accidental loss of
                  customer personal information, being used, accessed, changed
                  or disclosed without permission. Company limited access to
                  customer personal information for employees, agents,
                  contractors and third parties with 'Needs to be informed.
                </p>
              </div>
              <div>
                <h4>
                  11. Application of Personal Information Protection Policy
                </h4>
                <p>
                  This Privacy Policy applies to all personal data collected by
                  the company. The client agrees that the company has the right
                  to retain and take the Customer Personal Information that is
                  currently stored by the company and to be stored in the future
                  for use or disclosed to other parties within the scope as
                  specified in this Privacy Policy.
                </p>
              </div>
              <div>
                <h4>
                  12. Contact channels
                </h4>
                <span>
                  The company has assigned and appointed Ms. Kanchana Wangsuk to
                  be the Data Protection Officer. By giving powers and duties as
                  the Personal Data Protection Officer as stipulated by the
                  Personal Data Protection Act 2019 and is the coordinator of
                  personal information protection of the company Location:
                  Fusion Solution Co., Ltd. 77/148 Sinsathorn Tower 34th Floor,
                  Klong Ton Sai Subdistrict, Khlong San District, Bangkok In
                  case there is a complaint about the company, Employees or
                  employees of the company violate or fail to comply with the
                  law. The subject of personal information can make a complaint
                  to the supervisory authority Details are as follows. <br />
                  <span style="font-weight: bold"
                    >Office of the Personal Data Protection Committee</span
                  >
                  <br />Location: 7th Floor, Ratthaprasasanabhakdi Building 80th
                  Anniversary Government Center, Chaeng Watthana Road, Thung
                  Song Hong Subdistrict, Lak Si District, Bangkok 10210 <br />By
                  the resolution of the Board of Directors announced on</span
                ><span style="color:#1864d6"> 09 April 2021</span>
              </div>
              <br />
              <p style="font-weight: bold">(Mr. Supakit Yongvititsathit)</p>
              <p style="font-weight: bold">Director</p>
             
            </div>
          </v-card-text>
          <v-card-actions><v-spacer />
          <v-btn
            @click="privacydialog = false"
            color="#a6cc39"
            style="text-transform:none;color:white;font-size:16px;padding:10px;"
          >
            Close
          </v-btn>
          <v-spacer/></v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog width="600" v-model="cookiesdialog" persistent>
      <v-card class="mx-auto py-3" style="border-radius: 8px">
        <v-card-title>
          <v-spacer></v-spacer>
          <v-icon @click="cookiesdialog = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <!-- th -->
          <div>
            <h4 style="text-align:center;">นโยบายเกี่ยวกับการใช้งานคุกกี้</h4>
            <p>
              ในนโยบายนี้ คำว่า &quot;คุกกี้&quot; ที่เราใช้ หมายถึง คุกกี้
              และเทคโนโลยีอื่น ๆ ที่คล้ายคลึงกัน ซึ่งอยู่ภายใต้ข้อกำหนดตาม
              &quot;พระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล&quot;
              ด้วยการคุ้มครองความเป็นส่วนตัวในการติดต่อสื่อสารทางอิเล็กทรอนิกส์
            </p>
            <h4>คุกกี้ คืออะไร</h4>
            <p>
              คุกกี้
              คือชิ้นส่วนข้อมูลที่เว็บไซต์ขอให้เบราว์เซอร์จัดเก็บไว้ในคอมพิวเตอร์
              หรืออุปกรณ์พกพาของคุณ คุกกี้ช่วยให้เว็บไซต์ &quot;จดจำ&quot;
              การใช้งาน และการตั้งค่าความต้องการของผู้ใช้เอาไว้<br />
              อินเทอร์เน็ตเบราว์เซอร์ส่วนใหญ่รองรับคุกกี้ แต่อย่างไรก็ตาม
              ผู้ใช้งานสามารถตั้งค่าเบราวเซอร์ของตัวเองไม่ให้รับคุกกี้บางประเภท
              หรือคุกกี้อย่างใดอย่างหนึ่งได้ นอกจากนี้
              ผู้ใช้งานยังสามารถลบคุกกี้เมื่อไรก็ได้
            </p>
            <h4>ทำไมเราจึงใช้คุกกี้</h4>
            <p>
              เราใช้คุกกี้เพื่อให้ทราบได้ว่า
              คุณมีปฏิสัมพันธ์กับเนื้อหาในเว็บไซต์ของเราอย่างไร
              และเพื่อช่วยปรับปรุงพัฒนาประสบการณ์การใช้งานให้ดีขึ้นเมื่อคุณเข้าเยี่ยมชมเว็บไซต์ของเรา
              ตัวอย่างเช่น คุกกี้บางตัวจะจดจำการเลือกภาษา หรือการตั้งค่าของคุณ
              ทำให้คุณไม่ต้องตั้งค่าซ้ำเมื่อกลับเข้ามาที่เว็บไซต์อีกครั้ง<br />
              นอกจากนี้เรายังใช้คุกกี้
              เพื่อช่วยให้เรานำเสนอข้อมูลที่เหมาะสมสำหรับคุณได้ เช่น
              วิดีโอในเว็บไซต์
              โดยเราอาจศึกษาพฤติกรรมการใช้งานของคุณในเว็บไซต์เพื่อแสดงโฆษณาแบบกำหนดกลุ่มเป้าหมายบนเว็บไซต์ของบุคคลภายนอกเพื่อ
              &quot;ทำการตลาด&quot; ผลิตภัณฑ์และบริการของเราให้แก่คุณอีกครั้ง
            </p>
            <h4>เราใช้คุกกี้ประเภทใดบ้างในเว็บไซต์ของเรา</h4>
            <h4>คุกกี้ของเราและของบุคคลภายนอก</h4>
            <span
              >กกี้ของเราคือคุกกี้ที่ออกโดยโดเมนของเว็บไซต์ซึ่งโดยทั่วไปแล้วจะใช้เพื่อระบุการตั้งค่าภาษาและตำแหน่งที่ผู้ใช้งานเลือกหรือเพื่อช่วยในการแสดงผลเพื่อการใช้งานพื้นฐานของเว็บไซต์</span
            >
            <a href="https://www.seedkm.com/" style="color:#1864d6;"
              >https://www.seedkm.com/</a
            >
            <span
              >คุกกี้ของบุคคลภายนอก คือคุ้กกี้ที่ออก และบริหารจัดการโดยบุคคลอื่น
              เช่น พันธมิตรทางธุรกิจของ Seed หรือผู้ให้บริการต่าง ๆ
              คุกกี้ประเภทนี้อาจจำเป็นสำหรับการแสดงแบบฟอร์มบางอย่างบนเว็บไซต์
              เช่น การส่งแบบฟอร์มการสมัครงาน
              หรืออนุญาตให้มีการโฆษณานอกเว็บไซต์ของ Seed
            </span>
            <h4>คุกกี้แบบช่วงเวลา</h4>
            <p>
              คุกกี้แบบช่วงเวลา
              คือคุกกี้ชั่วคราวที่ใช้ในการจดจำคุณระหว่างที่คุณเข้ามาชมเว็บไซต์
              และจะหมดอายุลงเมื่อคุณปิดเว็บเบราว์เซอร์
            </p>
            <h4>คุกกี้ถาวร</h4>
            <p>
              คุกกี้ถาวร ใช้เพื่อจดจำความชอบของคุณภายในเว็บไซต์
              และจะยังอยู่ในเดสก์ท็อปหรืออุปกรณ์พกพาของคุณ
              แม้ว่าคุณจะปิดเบราว์เซอร์ไปแล้ว และหรือเปิดเครื่องใหม่ก็ตาม
              เราใช้คุกกี้เหล่านี้ในการวิเคราะหฺ์พฤติกรรมของผู้ใช้งานเพื่อสร้างรูปแบบการเข้าชม
              เพื่อที่ว่าเราจะสามารถปรับปรุงฟังก์ชั่นของเว็บไซต์สำหรับคุณ
              และผู้อื่นที่เข้ามาขมเว็บไซต์ของ Seed
              คุกกี้เหล่านี้ยังช่วยให้เราสามารถนำคุณไปยังโฆษณาที่กำหนดกลุ่มเป้าหมายเอาไว้
              และสามารถวัดประสิทธิผลของฟังก์ชั่นของไซต์ และโฆษณาของเราได้ด้วย
            </p>
            <h4>
              คำอธิบายตัวอย่างการนำคุกกี้ไปใช้สำหรับจุดประสงค์เพื่อการโฆษณา
              ได้อย่างไร
            </h4>
            <p>
              คุกกี้ และเทคโนโลยีในการโฆษณา เช่น เว็บบีคอน พิกเซลส์
              และแท็กของเครือข่ายโฆษณาแบบไม่ระบุตัวตน
              ช่วยให้เรานำเสนอโฆษณาที่คุณน่าจะสนใจได้อย่างมีประสิทธิภาพมากขึ้น
              เทคโนโลยีเหล่านี้ยังช่วยให้เราเก็บข้อมูลโดยรวมเพื่อตรวจสอบ
              การศึกษาวิจัย และการจัดทำรายงานด้านประสิทธิภาพสำหรับบริษัทโฆษณา
              พิกเซลส์ทำให้เราเข้าใจและสามารถปรับปรุงการนำเสนอโฆษณาแก่คุณ
              และทำให้เราทราบว่าคุณได้รับชมโฆษณาใดไปแล้ว
              และเนื่องจากเบราว์เซอร์ของคุณอาจจะทำการดึงโฆษณาและเว็บบีคอนได้โดยตรงจากเซิร์ฟเวอร์เครือข่ายโฆษณา
              เครือข่ายเหล่านี้จึงสามารถดู แก้ไข
              แม้ว่าเราจะไม่ใช้คุกกี้ในการสร้างโปรไฟล์ของคุณจากประวัติการเยี่ยมชมเว็บไซต์อื่น
              ๆ ก็ตาม
              แต่เราใช้ข้อมูลโดยรวมจากเว็บไซต์เหล่านี้ในการแสดงโฆษณาที่เกี่ยวข้อง
              และอิงจากความสนใจของคุณ
              เราจะไม่นำข้อมูลส่วนบุคคลของคุณไปให้บริษัทโฆษณา
              คุณสามารถเลือกที่จะไม่รับโฆษณานอกไซต์หรือโฆษณาที่แจ้งข้อมูลแก่บุคคลภายนอกได้
              โดยการตั้งค่าคุกกี้ในเครื่องของคุณ
              การตั้งค่านี้มิได้ทำให้โฆษณาหายไปจากหน้าที่คุณเข้าชม
              แต่โฆษณาที่คุณได้รับนั้นจะไม่ได้ตรงกับความสนใจของคุณ
              ซึ่งหมายความว่าโฆษณาจะไม่ถูกจับคู่กับความสนใจของคุณที่คุกกี้จัดเก็บเอาไว้นั่นเอง
              สำหรับการทำงานบางอย่างภายในเว็บไซต์ของเรานั้น
              เราจะใช้บริการที่จัดหาให้โดยบุคคลภายนอก ตัวอย่างเช่น
              เมื่อคุณเข้ามาเยี่ยมชมเว็บเพจที่มีวีดีโอจาก YouTube ฝังอยู่
              หรือมีลิงก์เชื่อมไปยัง YouTube วีดีโอหรือลิงก์เหล่านี้
              (และเนื้อหาอื่น ๆ ที่จัดหาให้โดยบุคคลภายนอก)
              อาจมีคุกกี้ของบุคคลภายนอกอยู่ด้วย
              และเราแนะนำให้คุณอ่านดูนโยบายคุ้มครองข้อมูลส่วนบุคคลของเว็บไซต์ของบุคคลภายนอกเหล่านี้เพื่อหาข้อมูลเกี่ยวกับการใช้คุกกี้ของพวกเขา
            </p>
            <h4>ทำอย่างไรจึงจะปฏิเสธ และลบคุกกี้ได้</h4>
            <p>
              คุณสามารถเลือกที่จะปฏิเสธ หรือสกัดกั้นคุกกี้ทั้งหมด
              หรือคุกกี้บางประเภทที่เว็บไซต์ Seed กำหนดไว้ให้คุณได้
              โดยคลิกที่ปุ่มตั้งค่าคุกกี้ในเว็บไซต์ของเรา
              คุณสามารถเปลี่ยนความต้องการของคุณบน Seed เว็บไซต์
              และ/หรือเว็บไซต์ของผู้จัดหาบริการที่เป็นบุคคลภายนอกกำหนดไว้ให้คุณได้
              โดยเปลี่ยนค่าที่ตั้งไว้ในเบราว์เซอร์ของคุณ
              โปรดทราบด้วยว่าเว็บเบราว์เซอร์ส่วนใหญ่จะยอมรับคุกกี้โดยอัตโนมัติ
              ดังนั้น หากคุณไม่ประสงค์ที่จะให้ใช้คุกกี้
              คุณอาจจะต้องคอยสกัดกั้นหรือลบคุกกี้ทิ้งอยู่เสมอ
              หากว่าคุณปฏิเสธการใช้คุกกี้
              คุณก็ยังคงสามารถที่จะเข้าเยี่ยมชมเว็บไซต์ของเราได้
              แต่การทำงานบางอย่างบนเว็บไซต์อาจไม่ถูกต้องหรือไม่ดีเท่าที่ควร
              คุณสามารถดูรายละเอียดเกี่ยวกับวิธีการปฏิเสธ หรือการลบคุกกี้
              ตลอดจนข้อมูลเพิ่มเติมทั่วไปเกี่ยวกับคุกกี้ได้ที่
            </p>
            <p style="font-weight: bold">
              Website Cookies: www.allaboutcookies.org/manage-cookies
            </p>
            <p style="font-weight: bold">
              Mobile Cookies: www.allaboutcookies.org/mobile/index.html
            </p>
            <p style="font-weight: bold">
              Internet Advertising Bureau (IAB):
              www.youronlinechoices.com/uk/your-ad-choices
            </p>
            <p style="font-weight: bold">
              Network Advertising Initiative (NAI):
              optout.networkadvertising.org/?c=1#!/
            </p>
            <p style="font-weight: bold">
              The Digital Advertising Alliance (DAA):
              digitaladvertisingalliance.org/
            </p>
            <p>
              การเยี่ยมชมเว็บไซต์โดยไม่ลบหรือปฏิเสธคุกกี้นั้น
              ถือว่าคุณตกลงอนุญาตให้ Seed
              จัดเก็บคุกกี้ที่คุณไม่ปฏิเสธไว้บนเครื่องของคุณได้
            </p>
            <h4>คุณจะลบคุกกี้ได้อย่างไร</h4>
            <p>
              คุกกี้แบบช่วงเวลา
              คือคุกกี้ชั่วคราวที่ใช้ในการจดจำคุณระหว่างที่คุณเข้ามาชมเว็บไซต์
              และจะหมดอายุลงเมื่อคุณปิดเว็บเบราว์เซอร
            </p>
            <p style="font-weight: bold">
              Google Chrome <br />
              Firefox <br />
              Internet Explorer <br />
              Safari <br />
              Safari for ios <br />
              Chrome for android <br />
              Chrome for ios
            </p>
            <h4>การเปลี่ยนแปลงของนโยบายของเรา</h4>
            <p>
              นโยบายคุกกี้นี้จะมีการปรับปรุงแก้ไขตามโอกาสเพื่อปฎิบัติตามกฎระเบียบ
              เราขอแนะนำให้ท่านตรวจเช็คเพื่อให้แน่ใจว่าท่านได้เข้าใจการเปลี่ยนแปลงข้อกำหนดดังกล่าว <br/>
              การที่ลูกค้าหรือผู้ใช้บริการนำเข้าข้อมูลที่ละเมิดต่อกฎหมาย  ละเมิดลิขสิทธิ์  
              ละเมิดทรัพย์สินทางปัญญา  ละเมิดข้อมูลส่วนบุคคลของบุคคลใด  บนระบบให้บริการของบริษัทหรือบน 
              ลูกค้าหรือผู้ใช้บริการต้องรับผิดชอบในการละเมิดดังกล่าวด้วยตนเอง โดยบริษัทจะไม่ร่วมรับผิดชอบไม่ว่ากรณีใด ๆ
            </p>
            <span>* ปรับปรับ / แก้ไขล่าสุด : </span
            ><span style="color:#1864d6">10 พฤศจิกายน 2564</span><br />
            <p style="font-weight: bold;margin-top:5px;">
              (นายศุภกิจ ยงวิทิตสถิต )
            </p>
            <p style="font-weight: bold">กรรมการ</p>
          </div>
          <!-- #region en -->
          <div>
            <h4 style="text-align:center;">Cookie Policy</h4>
            <p>
              In this policy, the term "cookies" is used to refer to
              cookies and other similar technologies which is subject to the
              "Personal Information Protection Act" with the
              protection of privacy in electronic communications.
            </p>
            <h4>What are cookies?</h4>
            <p>
              Cookies are pieces of information that websites ask your browser
              to store on your computer or your portable device. Cookies help
              the website to "remember" the user's usage and preferences.
              <br />Most Internet browsers support cookies. However, users can
              set their own browsers not to accept certain types of cookies. In
              addition, the user can also delete cookies at any time.
            </p>
            <h4>Why do we use cookies?</h4>
            <p>
              We use cookies to determine how you interact with the content on
              our website and to help improve your experience when you visit our
              website, for example, some cookies will remember your language
              selection or your settings, so you don't have to set it up again
              when returning to the website. <br />
              We also use cookies to help us provide you with suitable
              information, such as videos on our website. We may study your
              behavior on the Site to show targeted advertisements on third
              party websites in order to "market" our products and
              services to you again.
            </p>
            <h4>What types of cookies do we use on our website?</h4>
            <h4>Our and third-party cookies</h4>
            <span>Our cookies are cookies issued by a website's domain</span>
            <a href="https://www.seedkm.com/" style="color:#1864d6;"
              >https://www.seedkm.com/</a
            ><span
              >this is typically used to specify the language setting. And
              user-selected position to help display results for the basic use
              of the website Third party cookies are the cookies released
              managed by third parties, such as Seed business partners or
              service providers. These cookies may be required for displaying
              certain forms on the website, such as submitting a job application
              form. Or allow off-site advertising of Seed.
            </span>
            <h4>Interval Cookies</h4>
            <p>
              These are temporary cookies that are used to recognize you while
              you visit the website. And will expire when you close your web
              browser.
            </p>
            <h4>Persistent cookies</h4>
            <p>
              Persistent cookies are used to remember your preferences within 
              the website. And it will still be on your desktop or mobile device 
              even if you close your browser and or open a new device. We use 
              these cookies to analyze user behavior in order to generate 
              traffic patterns so that we can improve the functions of the 
              website for you. And others who visit Optimistic's website. These 
              cookies also allow us to direct you to targeted advertisements. 
              And can measure the effectiveness of the site's functions and our 
              advertisements too.
            </p>
            <h4>
              Explanation of examples of how cookies may be used for advertising
              purposes.
            </h4>
            <p>
              Cookies and advertising technologies such as web beacons, pixels 
              and anonymous ad network tags. It allows us to deliver the ads you 
              may be interested in more effectively. These technologies also 
              help us collect collective information for review., research 
              studies and preparing performance reports for advertising 
              companies. Pixels allow us to understand and improve the delivery 
              of advertisements to you and let us know which ads you've been 
              watching And because your browser might be able to retrieve ads 
              and web beacons directly from the ad network server. These 
              networks can then view, modify or set their own cookies as if you 
              were manually requesting to retrieve web pages from that network's 
              site.
              <br />
              While we do not use cookies to build your profile from the history
              of other websites, we use aggregate information from these
              websites to show you relevant advertisements. And according to
              your interests We do not take your personal information to
              advertising companies. You may opt out of receiving off-site
              advertising or advertising that informs third parties. By setting
              cookies in your machine, this setting does not cause ads to
              disappear from the pages you have visited. But the ads you receive
              will not match your interests. This means that the ads will not be
              matched to the interests you have that the cookies hold. <br />
              For certain functions within our website, we will use services
              provided by third parties, for example, when you visit a web page
              that contains YouTube videos embedded or links to YouTube videos
              or links. (And other content provided by third parties) may
              contain third party cookies. <br />
              And we encourage you to review the privacy policies of these
              third-party websites to find out about their use of cookies.
            </p>
            <h4>How to refuse and can delete cookies</h4>
            <p>
              You can choose to decline. Or block all cookies or certain cookies
              that the Seed website can set for you. By clicking on the set
              cookies button on our website you can change your preferences on
              the Seed website and / or the third-party service provider's
              website designated for you. By changing the settings in your
              browser Please note that most web browsers automatically accept
              cookies, so if you do not wish to use cookies, you may need to
              keep blocking or deleting cookies. If you refuse to use cookies.
              You will still be able to visit our website. But some functions on
              the website may not be correct or not as good as they should be.
              You can find details on how to decline. Or deleting cookies as
              well as general additional information about cookies can be found
              at
            </p>
            <p style="font-weight: bold">
              Website Cookies: www.allaboutcookies.org/manage-cookies
            </p>
            <p style="font-weight: bold">
              Mobile Cookies: www.allaboutcookies.org/mobile/index.html
            </p>
            <p style="font-weight: bold">
              Internet Advertising Bureau (IAB):
              www.youronlinechoices.com/uk/your-ad-choices
            </p>
            <p style="font-weight: bold">
              Network Advertising Initiative (NAI): 
              optout.networkadvertising.org/?c=1#!/
            </p>
            <p style="font-weight: bold">
              The Digital Advertising Alliance (DAA):
              digitaladvertisingalliance.org/
            </p>
            <p>
              Visiting a website without deleting or rejecting that cookie. You
              agree to allow Seed to store cookies that you do not reject on
              your device. How do you delete cookies? You can delete cookies by
              following the following links: For example, on the web browser you
              choose to use.
            </p>
            <p style="font-weight: bold">
              Google Chrome <br />
              Firefox <br />
              Internet Explorer <br />
              Safari <br />
              Safari for ios <br />
              Chrome for android <br />
              Chrome for ios
            </p>
            <h4>Changes to our policy</h4>
            <span style="display: block"
              >This Cookie Policy will be updated occasionally to comply with
              regulations. We encourage you to check to make sure you understand
              the changes.  <br/>
              The fact that the customer or the service user imports data that 
              violates the law. Piracy intellectual property infringement 
              Violating any person's privacy on the company's service system 
              or on Customers or service users are responsible for such 
              violations themselves. The company will not be jointly responsible 
              in any case.
              * Adjustments / Last Modifications:</span
            ><span style="color:#1864d6;display: block"> 10 November 2021</span>
            <p style="font-weight: bold">(Mr. Supakit Yongvititsathit)</p>
            <p style="font-weight: bold">Director</p>
          </div>
          <!-- #endregion -->
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            @click="cookiesdialog = false"
            color="#a6cc39"
            style="text-transform:none;color:white;font-size:16px;padding:10px;"
          >
            Close
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      privacydialog: false,
      cookiesdialog: false,
      chkAccept: false,
    };
  },
  computed: {
    dialog: {
      get() {
        return this.show;
      },
      set() {
        this.$emit("accept");
      },
    },
  },
  methods: {
    async UpdatePolicy() {
      let self=this;
      self.dialog = false;
      let request = {
        id:parseInt(localStorage.getItem("UserID"))
      };
     await axios.post(`${self.web_url}User/UpdatePolicyWeb`, request).then(function(response) {
        if (response.data.status == 0) {
          localStorage.setItem("policyType",true);
        self.$router.push("/home/library");
        }
      });
    },
    Later() {
      this.dialog = false;
       localStorage.setItem("vivek_authenticated_user_data", "");
      localStorage.setItem("vivek_token", "");
      localStorage.clear();
      this.$router.push("/login");
      let expire = "Thu, 01 Jan 1970 00:00:00 GMT;";
       document.cookie = "langprod=;" + ";expires=" + expire;
        document.cookie = "flagprod=;" + ";expires=" + expire;
        document.cookie = "titleprod=;" + ";expires=" + expire;
        document.cookie = "highlighted=;" + ";expires=" + expire;
    },
    // Accept() {
    //   //this.$emit("accept");
    //   this.UpdatePolicy();
    //   //this.$router.push("/home/library");
    // },
  },
};
</script>
